import React, { useState } from 'react'
import { Form, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import { message } from 'antd'
import { useHistory } from 'react-router'

import { syncCertificates } from '../services'
import { handleError } from '../../../helpers'

export default function CertificateForm() {
	const user = useSelector(state => state.auth.user)
	const history = useHistory()
	const [loading, setLoading] = useState(false)
	const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm({
		defaultValues: { 
			type: '',
			year: '2024',
			is_multiperiod: '0'
		}
	})

	const onSubmit = values => {
		setLoading(true)
		syncCertificates({
			'supplier_document': user.document,
			'type': values.type,
			'year': values.year,
			'month': values.month,
			'range_month': values.range_month
		})
			.then(res => {
				if(res.data.data.length === 0){
					message.warning(res.data.message)
					setValue('month', '')
					setValue('range_month', '')
					setLoading(false)
				} else {
					history.push(`/certificados/${values.type}/${values.year}/${values.month}${values.range_month ? `/${values.range_month}` : ''}`)
				}
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Form.Group className='form-group'>
					<Form.Label>Tipo de Certificado <span className='text-primary'>*</span></Form.Label>
					<Form.Control
						as="select"
						{...register('type', { required: true })}
					>
						<option value="">:: Seleccione una opción ::</option>
						<option value="1">1 - Retencion IVA</option>
						<option value="2">2 - Retencion ICA</option>
						<option value="3">3 - Retencion en la Fuente</option>
						<option value="4">4 - Retencion Imp de Timbre</option>
					</Form.Control>
					{ errors.type && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group className='form-group'>
					<Form.Label>Año Fiscal <span className='text-primary'>*</span></Form.Label>
					<Form.Control
						as="select"
						{...register('year', { required:true })}
						onChange={e => {
							setValue('year', e.target.value)
							setValue('month', '')
							setValue('range_month', '')
						}}
					>
						<option value="">:: Seleccione una opción ::</option>
						<option>2023</option>
						<option>2024</option>
						<option>2025</option>
					</Form.Control>
					{ errors.year && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group className='form-group'>
					<Form.Control
						as="select"
						{...register('is_multiperiod', { required:true })}
						disabled={watch('type') === ''}
					>
						<option value="0">Certificado para un solo periodo</option>
						<option value="1">Certificado para multiples periodos</option>
					</Form.Control>
					{ errors.year && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group className='form-group'>
					<Form.Label>Periodo {watch('is_multiperiod') === '1' ? 'Inicial' : ''} <span className='text-primary'>*</span></Form.Label>
					<Form.Control
						as="select"
						{...register('month', { required:true })}
						onChange={e => {
							setValue('month', e.target.value)
							setValue('range_month', '')
						}}
					>
						<option value="">:: Seleccione una opción ::</option>
						<option value="1" disabled={parseInt(watch('year')) === 2023 && watch('is_multiperiod') === '1'}>1 - Enero</option>
						<option value="2">2 - Febrero</option>
						{ watch('year') !== '2025' && (
							<>
								<option value="3">3 - Marzo</option>
								<option value="4">4 - Abril</option>
								<option value="5">5 - Mayo</option>
								<option value="6">6 - Junio</option>
								<option value="7">7 - Julio</option>
								<option value="8">8 - Agosto</option>
								<option value="9">9 - Septiembre</option>
								<option value="10">10 - Octubre</option>
								<option value="11">11 - Noviembre</option>
								<option value="12" disabled={watch('is_multiperiod') === '1'}>12 - Diciembre</option>
							</>
						)}
					</Form.Control>
					{ errors.month && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				{watch('is_multiperiod') === '1' && (
					<Form.Group className='form-group'>
						<Form.Label>Periodo Final <span className='text-primary'>*</span></Form.Label>
						<Form.Control
							as="select"
							{...register('range_month', { required:true })}
						>
							<option value="">:: Seleccione una opción ::</option>
							<option value="1" disabled={true}>1 - Enero</option>
							<option value="2" disabled={parseInt(watch('month')) >= 2}>2 - Febrero</option>
							{ watch('year') !== '2025' && (
								<>
									<option value="3" disabled={parseInt(watch('month')) >= 3}>3 - Marzo</option>
									<option value="4" disabled={parseInt(watch('month')) >= 4}>4 - Abril</option>
									<option value="5" disabled={parseInt(watch('month')) >= 5}>5 - Mayo</option>
									<option value="6" disabled={parseInt(watch('month')) >= 6}>6 - Junio</option>
									<option value="7" disabled={parseInt(watch('month')) >= 7}>7 - Julio</option>
									<option value="8" disabled={parseInt(watch('month')) >= 8}>8 - Agosto</option>
									<option value="9" disabled={parseInt(watch('month')) >= 9}>9 - Septiembre</option>
									<option value="10" disabled={parseInt(watch('month')) >= 10}>10 - Octubre</option>
									<option value="11" disabled={parseInt(watch('month')) >= 11}>11 - Noviembre</option>
									<option value="12" disabled={parseInt(watch('month')) >= 12}>12 - Diciembre</option>
								</>
							)}
						</Form.Control>
						{ errors.range_month && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
					</Form.Group>
				)}
				<Button color='primary' type="submit" disabled={loading}>
					{loading && <Spinner animation='border' size="sm" className='mr-5' />}
					Consultar Certificado
				</Button>
			</Form>
		</React.Fragment>
	)
}