import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Spinner } from 'react-bootstrap'

import CertificatePreviewLayout from './partials/CertificatePreviewLayout'

import { getCertificates } from './services'
import { currency, handleError } from '../../helpers'
import { typeName, getTotal, getBaseTotal, groupByMonthAndRate, groupByMonthAndCity } from './helpers'

export default function CertificateRangePreview() {
	const user = useSelector(state => state.auth.user)
	const { type, year, month, range_month } = useParams()
	const [certificates, setCertificates] = useState(null)

	useEffect(() => {
	  !certificates && getCertificates({
			'filter[supplier_id]': user.id,
			'filter[type]': type,
			'filter[year]': year,
			'filter[range_month]': `${month},${range_month}`,
			include: 'supplier,invoice',
			per_page: 10000000000,
		})
			.then(res => {
				setCertificates(res.data.data)
			})
			.catch(error => handleError(error))
	}, [ certificates, user, type, year, month, range_month ])

	if(!certificates) return <Spinner animation='border' />

	const groupedCertificates = type === '2' ? groupByMonthAndCity(certificates) : groupByMonthAndRate(certificates) ?? []

	console.log(groupedCertificates)

	return (
		<React.Fragment>
			<CertificatePreviewLayout 
				type={type} 
				year={year} 
				month={month} 
				range_month={range_month}
				certificates={certificates}
			>
				<table className='table mb-30'>
					<thead>
						<tr>
							<th>Periodo</th>
							<th>Concepto de la operación</th>
							<th>Valor base de la retención</th>
							<th>%</th>
							<th>Retención aplicada</th>
						</tr>
					</thead>
					<tbody>
						{ groupedCertificates.map(certificate => (
							<React.Fragment key={certificate.id}>
								{ Object.keys(certificate).map(groupKey => (
									<tr key={groupKey}>
										<td>{certificate[groupKey].month_name.toUpperCase()}</td>
										<td>{typeName(type).replace('CERTIFICADO DE ', '')} TARIFA {type === '2' ? groupKey : `${groupKey}%`}</td>
										<td>$ {currency(certificate[groupKey].base)} COP</td>
										<td>{certificate[groupKey].rate.toFixed(2)} %</td>
										<td>$ {currency(certificate[groupKey].value)} COP</td>
									</tr>
								))}
							</React.Fragment>
						))}
						<tr className='bold'>
							<td className='text-right pr-20'>TOTAL:</td>
							<td></td>
							<td>$ {currency(getBaseTotal(certificates))} COP</td>
							<td></td>
							<td>$ {currency(getTotal(certificates))} COP</td>
						</tr>
					</tbody>
				</table>
			</CertificatePreviewLayout>
		</React.Fragment>
	)
}