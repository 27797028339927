import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import { Empty } from 'antd'
import moment from 'moment'

import { typeName, getLocations } from '../helpers'

export default function CertificatePreviewLayout({ type, year, month, range_month, certificates, children }) {
	return (
        <React.Fragment>
            <div className='mb-20'>
                <div className='float-right no-print'>
                    <Button color='primary' outline onClick={() => window.print()}>
                        Imprimir
                    </Button>
                </div>
            </div>
            <div className='pt-15 px-10'>
                <div className='text-center mb-20'>
                    <p className='mb-1 fs-16'><strong>Industrias Spring S.A.S</strong></p>
                    <p className='mb-1'>Aut. Mill.Ent.2.2 km Vía Cota</p>
                    <p className='mb-30'>NIT: 860000794-1</p>
                    <h6 className='mb-30 fs-16'>{typeName(type)}</h6>
                </div>
            </div>
            <Row className='px-30 mb-20'>
                <Col>
                    <p className='mb-0'>Fecha de expedición</p>
                    <p>{moment().format('DD.MM.YYYY')}</p>
                </Col>
                <Col>
                    <p className='mb-0'>Año Gravable</p>
                    <p>{year}</p>
                </Col>
                <Col>
                    <p className='mb-0'>Periodo</p>
                    {range_month ? (
                        <p>Del Periodo {month} al Periodo {range_month}</p>
                    ) : (
                        <p>{month}</p>
                    )}
                </Col>
            </Row>
            { certificates.length > 0 ? (
				<div className='px-30'>
					<p className='mb-0'>Certifica que practicó</p>
					<p className='mb-0'>{typeName(type).replace('CERTIFICADO DE ', '')} a: <strong>{certificates[0].supplier.name}</strong></p>
					<p>NIT/C.C:  <strong>{certificates[0].supplier.document}-{certificates[0].supplier.verification_digit}</strong></p>
					<p className='mb-30'>Lugar donde se practicó y se pago la retención: {getLocations(certificates)}</p>
					{ children }
                    
					<div className='text-center mt-30 pt-30'>
						<p className='small mb-0'><em>El presente certificado se expide en concordancia con las disposiciones legales contenidas en el artículo 381 del E.T.</em></p>
						<p className='small'><em>*** Forma Continua Impresa por Computador No necesita Firma Autografa ***</em></p>
					</div>
				</div>
			) : (
				<Empty description="No se encontro certificados para el periodo señalado." />
			)}
        </React.Fragment>
	)
}