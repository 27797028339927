export const typeName = (type) => {
    switch (parseInt(type)) {
        case 1: return 'CERTIFICADO DE RETENCION DE IVA'
        case 2: return 'CERTIFICADO DE RETENCION DE ICA'
        case 3: return 'CERTIFICADO DE RETENCION EN LA FUENTE'
        case 4: return 'CERTIFICADO DE RETENCION IMPUESTO DE TIMBRE'
    
        default: return 'SIN DEFINIR'
    }
}

export const getTotal = (certificates) => (
    certificates.reduce((total, certificate) => {
        return total + certificate.value;
    }, 0)
)

export const getBaseTotal = (certificates) => (
    certificates.reduce((total, certificate) => {
        return total + certificate.base;
    }, 0)
)

export const getLocations = (certificates) => {
    var locations = []
 
    for (var i = 0; i < certificates.length; i++){
        if(certificates[i].location && !locations.includes(certificates[i].location)) 
            locations.push(certificates[i].location)
    }
 
    return locations.join(", ")
}

export const groupByMonthAndRate = (certificates) => {
    const grouped = {}

    certificates.forEach(certificate => {
        const month = certificate.month
        const rate = certificate.rate

        if (!grouped[month]) {
            grouped[month] = {}
        }

        if (!grouped[month][rate]) {
            grouped[month][rate] = {
                id: certificate.id,
                month_name: certificate.month_name,
                rate: certificate.rate,
                base: certificate.base,
                value: certificate.value,
            }
        } else {
            grouped[month][rate].base += certificate.base
            grouped[month][rate].value += certificate.value
        }
    })

    return Object.values(grouped)
}

export const groupByMonthAndCity = (certificates) => {
    const grouped = {}

    certificates.forEach(certificate => {
        const month = certificate.month
        const city = certificate.location

        if (!grouped[month]) {
            grouped[month] = {}
        }

        if (!grouped[month][city]) {
            grouped[month][city] = {
                id: certificate.id,
                month_name: certificate.month_name,
                rate: certificate.rate,
                base: certificate.base,
                value: certificate.value,
            }
        } else {
            grouped[month][city].base += certificate.base
            grouped[month][city].value += certificate.value
        }
    })

    return Object.values(grouped)
}