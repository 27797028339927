import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Spinner } from 'react-bootstrap'

import CertificatePreviewLayout from './partials/CertificatePreviewLayout'

import { getCertificates } from './services'
import { currency, handleError } from '../../helpers'
import { getTotal, getBaseTotal } from './helpers'

export default function CertificatePreview() {
	const user = useSelector(state => state.auth.user)
	const { type, year, month } = useParams()
	const [certificates, setCertificates] = useState(null)

	useEffect(() => {
	  !certificates && getCertificates({
			'filter[supplier_id]': user.id,
			'filter[type]': type,
			'filter[year]': year,
			'filter[month]': month,
			include: 'supplier,invoice',
			per_page: 1000000,
		})
			.then(res => {
				setCertificates(res.data.data)
			})
			.catch(error => handleError(error))
	}, [ certificates, user, type, year, month ])

	if(!certificates) return <Spinner animation='border' />

	return (
		<React.Fragment>
			<CertificatePreviewLayout 
				type={type} 
				year={year} 
				month={month} 
				certificates={certificates}
			>
				<table className='table mb-30'>
					<thead>
						<tr>
							<th>Concepto de la operación</th>
							{ type === '2' && <th>Lugar donde se practicó y se pago</th> }
							<th>Valor base de la retención</th>
							<th>%</th>
							<th>Retención aplicada</th>
						</tr>
					</thead>
					<tbody>
						{ certificates.map(certificate => (
							<tr key={certificate.id}>
								<td>Retención aplicada a factura {certificate.invoice.consecutive}</td>
								{ type === '2' && <td>{certificate.location}</td> }
								<td>$ {currency(certificate.base)} COP</td>
								<td>{certificate.rate.toFixed(2)} %</td>
								<td>$ {currency(certificate.value)} COP</td>
							</tr>
						))}
						<tr className='bold'>
							<td className='text-right pr-20'>TOTAL:</td>
							{ type === '2' && <td></td> }
							<td>$ {currency(getBaseTotal(certificates))} COP</td>
							<td></td>
							<td>$ {currency(getTotal(certificates))} COP</td>
						</tr>
					</tbody>
				</table>
			</CertificatePreviewLayout>
		</React.Fragment>
	)
}