import springCenfApi from '../../services/springCenfApi'
import springCrmApi from '../../services/springCrmApi'

export const getCertificates = params => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.get('/certificates', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getCertificate = (id, params) => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.get(`/certificates/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const syncCertificates = data => {
	if(window.location.host.split('.')[0].includes('localhost')) {
		return Promise.resolve({ data: { data: [1,2,3] } })
	}

	return (
		new Promise((resolve, reject) => {
			springCrmApi()
				.post('/public/cenf/certificates/sync', data)
					.then(res => resolve(res))
					.catch(error => reject(error))
		})
	)
}