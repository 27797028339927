import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import CertificatesList from './CertificatesList'
import CertificatePreview from './CertificatePreview'
import CertificateRangePreview from './CertificateRangePreview'

export default function Certificates() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/certificados" component={CertificatesList} />
				<Route exact path="/certificados/:type/:year/:month" component={CertificatePreview} />
				<Route exact path="/certificados/:type/:year/:month/:range_month" component={CertificateRangePreview} />
				
				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}